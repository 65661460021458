import { h, render, Component } from 'preact'
import * as moment from 'moment'
import * as journalize from 'journalize'

// all the slugs for this agency
const BUILD_GUTEN_TAG_URL = ({ agency_slug }) => {
  return `/api/departments/${agency_slug}/guten_tags/`
}

// build url for our api call for stories/events
function BUILD_RELATED_CONTENT_URL(tagIDs, content_type) {
    var tag_ids_string = ''

    // make sure we return articles matching all the ids
    tagIDs.forEach(function(tag) {
      tag_ids_string += '&or_tag_id=' + tag
    })
  
    // pick url of the API, depending on the call we need to make
    var url = ''

    if (content_type == 'stories') {
      var numberOfStories = num_stories

      url = `https://www.texastribune.org/api/v2/articles/?${tag_ids_string}&fields=id,url,pub_date,headline,sitewide_image&limit=${numberOfStories}&format=json`
    }

    if (content_type == 'events') {
      var numberOfEvents = 1
      
      url = `https://www.texastribune.org/api/v2/events/upcoming/?${tag_ids_string}&fields=id,lead_art,starts_at,ends_at,eventbrite_url,name,venue_city,venue_state,display_date,override_url&limit=${numberOfEvents}&format=json`
    }

    return url
}

class RelatedGlobal extends Component {
  constructor() {
    super()

    this.state = {
      loadSucceeded: false,
      content: [],
      noAgencyTags: false,
    }
  }

  componentDidMount() {
    // if we're on a page affiliated w/ an agency
    if (agency_slug != "") {
      // first, we fetch guten tags related to the agency 
      fetch(BUILD_GUTEN_TAG_URL({agency_slug}))
        .then(res => res.json())
        .then(res => {
          // if there are associated guten tags, we grab their ids here
          // if not, guten_tags is []
          var guten_tags = res['guten_tags'].map(value => value.id)

          // if there's no tags affiliated with the agency
          if (guten_tags.length == 0) {
            this.setState({
              noAgencyTags: true
            })
          }

          // we fetch the content with the guten_tags
          // guten_tags = [] will just get the latest articles
          fetch(BUILD_RELATED_CONTENT_URL(guten_tags, this.props.content_type))
            .then(res => res.json())
            .then(res => {
              // if the agency has associated tags that don't fetch any events
              // which happens less w/ stories
              // we fetch events again with no tags which should return the latest upcoming event
              if (this.props.content_type == 'events' && res['results'].length == 0) {
                fetch(BUILD_RELATED_CONTENT_URL([], this.props.content_type))
                  .then(res => res.json())
                  .then(res => {
                    this.setState({
                      loadSucceeded: true,
                      content: res['results']
                    })
                  })
                // console.log(BUILD_RELATED_CONTENT_URL(guten_tags, this.props.content_type))
                // console.log('results', res['results'])
              } else {
                this.setState({
                  loadSucceeded: true,
                  content: res['results']
                })
              }
            })
        })
    } else {
      // if there's no agency, there's no guten tags
      // occurs on the homepage, about page, pages w/ no affiliated agency
      fetch(BUILD_RELATED_CONTENT_URL([], this.props.content_type))
        .then(res => res.json())
        .then(res => {
          this.setState({
            loadSucceeded: true,
            content: res['results']
          })
        })
    }
  }

  componentDidUpdate() {
    if (
      // account for 0 events by setting module-4 (event container
      // in area_one.html) and module-5 (ad next to event container) to display: none;
      // needs to happen after render because the state gets rendered with
      // no content initially
      this.props.content_type == 'events' && this.state.content.length == 0) {
      if (document.querySelector('.module-4') && document.querySelector('.module-5')) {
        document.querySelector('.module-4').setAttribute('style', 'display: none;')
        document.querySelector('.module-5').setAttribute('style', 'display: none;')
      }
    }
  }

  render() {
    // all related stories
    if (this.props.content_type == 'stories' && this.state.content.length != 0) {
      return <div class={`has-padding has-bg-${related_global_color} has-xxs-btm-marg`}>
        <p class="t-size-b t-weight-bold t-uppercase has-xxs-btm-marg">Latest news</p>
        {(page == 'index' || page == 'homepage' || this.state.noAgencyTags == true) ? '' : 
          <p class="t-size-s t-serif">Here's what our reporters have written related to the {agency}.</p>
        }
        {this.state.content.map(story => {
          return (
            <div class={(related_stories_layout === 'grid') ? "has-l-top-marg related-stories-grid" : "has-l-top-marg"}>
              <div>
                <a href={story.url} ga-event-category="read more" ga-event-action="automated by tag" ga-event-label="salaries">
                  <img loading="lazy" class="has-xxxs-btm-marg" src={story.sitewide_image.thumbnails.lightbox} alt={story.sitewide_image.description}/>
                </a>
              </div>
              <div>
                <p class={(related_stories_layout === 'grid') ? 
                "t-size-b t-serif t-weight-bold has-tiny-btm-marg t-links-underlined-hover" : 
                "t-size-s t-serif t-weight-bold has-tiny-btm-marg t-links-underlined-hover"}>
                  <a href={story.url} ga-event-category="read more" ga-event-action="automated by tag" ga-event-label="salaries">
                    {story.headline}
                  </a>
                </p>
                <p class={(related_stories_layout === 'grid') ? 
                "t-size-s has-text-gray-dark t-uppercase" :
                "t-size-xs has-text-gray-dark t-uppercase"}>
                  {journalize.apdate(new Date(story.pub_date))}
                </p>
              </div>
            </div>
          )
        })}
      </div> // all related events
    } else if (this.props.content_type == 'events' && this.state.content.length != 0) {
      return <div class={`has-padding has-bg-${related_global_color} has-xxs-btm-marg`}>
        <p class="t-size-b t-weight-bold t-uppercase has-xxs-btm-marg">Latest events</p>
        {this.state.content.map(event => {
          return (
            <div class="c-block-grid">
              <div class="c-event-block">
                <figure>
                  <a href={event.eventbrite_url || event.override_url} class="l-display-block" ga-event-category="upcoming events" ga-event-action="event image" ga-event-label="salaries">
                    <img loading="lazy" class="l-display-block l-width-full has-xxxs-btm-marg" src={event.lead_art.thumbnails.lightbox} alt="Event image"/>
                  </a>
                </figure>
                <time aria-hidden="true" class="c-event-block__tag l-display-ib has-bg-black-off t-uppercase t-lsp-b t-align-center t-weight-bold">
                  <div class="t-uppercase has-text-yellow t-size-xs t-lh-s">{journalize.apmonth(new Date(event.starts_at))}</div>
                  <div class="c-event-block__day t-size-giant has-text-white t-lh-s">{moment(event.starts_at).date()}</div>
                </time>
                <div class="c-cta-block__msg t-size-s has-xxs-btm-marg">
                  <h4 aria-hidden="true" class="has-xxxs-btm-marg t-serif t-links-underlined-hover t-size-b">
                    <a href={event.eventbrite_url || event.override_url} tabindex="-1" ga-event-category="upcoming events" ga-event-action="event title" ga-event-label="salaries">{event.name}</a>
                  </h4>
                  <p class="t-uppercase t-lsp-b t-size-xs">
                    <strong>
                      {(event.venue_city && event.venue_state) ? 
                        <span>{event.venue_city}, {event.venue_state}</span> :
                        <span>{event.venue_city}</span>
                      } | {moment(event.starts_at).format('LT')} - {moment(event.ends_at).format('LT')}
                    </strong>
                  </p>
                  <p class="is-sr-only">{event.display_date}</p>
                </div>
                <div class="c-cta-block__btns c-cta-block__btns--2 t-align-center l-align-end-self t-size-s">
                  <a class="c-button c-button--standard has-bg-blue has-text-black l-align-center-children" href={event.eventbrite_url || event.override_url} ga-event-category="upcoming events" ga-event-action="attend" ga-event-label="salaries">
                    Attend
                  </a>
                  <a class="c-button c-button--standard has-bg-gray-dark has-text-white l-align-center-children" href={event.livestream_url} ga-event-category="upcoming events" ga-event-action="watch" ga-event-label="salaries">
                    Watch
                  </a>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    }
  }
}

export default RelatedGlobal
